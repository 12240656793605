import React, { useCallback, useEffect, useState } from 'react';
import './LoginPage.scss';
import { loadFromApi } from '../../../api/baseApi';
import { useToasts } from 'react-toast-notifications';
import { FORGOT_PASSWORD_URL } from '../../../api/loginApi';
import loginPage from '../../../assets/images/loginpage.png';
import { Helmet } from 'react-helmet';
import { ResetPassword } from '../resetPasswordPage/ResetPassword';
import LoadingDisplay from '../../common/LoadingDisplay';
import { QRCodeSVG } from 'qrcode.react';

export const LoginPage = (props: any) => {
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const [userName, setUsername] = useState('');
    const [passwordExpired, setPasswordExpired] = useState(false);
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [token, setToken] = useState('');
    const [error, setError] = useState(false);
    const [qrCode, showQrCode] = useState(false);
    const [otpUri, setOtpUri] = useState('');
    const [mfaRequired, setMfaRequired] = useState(false);
    const [verifiedByEmail, setVerifiedByEmail] = useState(false);
    const [verifiedByEmailError, setVerifiedByEmailError] = useState(false);
    const [loading, setLoading] = useState(false);
    const validateFields = () => {
        setLoading(true);
        const LoginPageApiWrapper = loadFromApi(setApiError);
        const loadUpdateAccountData = async () => {
            var formdata = new FormData();
            formdata.append('Username', `${userName}`);
            formdata.append('Password', `${password}`);
            formdata.append('VerifcationCode', `${verificationCode}`);
            formdata.append('VerifiedByEmail', `${verifiedByEmail}`);
            var requestOptions: any = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            const LOGIN_URL = process.env.REACT_APP_LOGIN_PAGE_URL + '/validateuser';
            LOGIN_URL &&
                fetch(LOGIN_URL, requestOptions)
                    .then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            return 'error';
                        }
                    })
                    .then((result: any) => {
                        setLoading(false);
                        if (result !== 'error') {
                            if (result?.passwordExpired) {
                                sessionStorage.setItem('userName', userName);
                                props.setPasswordExpired(true);
                                window.location.href = '/resetPassword';
                            }
                            if (result.passwordExpires <= 10) {
                                addToast(`Password expires in ${result.PasswordExpires} days`, {
                                    appearance: 'info',
                                    autoDismiss: false
                                });
                            }
                            setToken(result.token);
                            props.setAuthToken && props.setAuthToken(result.token);
                        } else {
                            setError(true);
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        console.log('error', error);
                    });
        };
        loadUpdateAccountData();
    };
    const loadMfaProperties = () => {
        const checkMfaRequired_Url =
            process.env.REACT_APP_COMPANY_API_URL + `/Users/MfaStatus/${userName}/${password}`;
        var requestOptions: any = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(checkMfaRequired_Url, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return 'error';
                }
            })
            .then((result: any) => {
                setLoading(false);
                if (result !== 'error') {
                    if (!result?.mfaRequired) {
                        validateFields();
                    } else if (result?.setupAuthenticator) {
                        showQrCode(true);
                        const otpUri = `otpauth://totp/Reliant:${userName}?secret=${result.mfaSetupToken}&issuer=Reliant`;
                        setOtpUri(otpUri);
                    } else {
                        setMfaRequired(true);
                        showQrCode(false);
                    }
                } else {
                    setError(true);
                }
            })
            .catch(error => {
                setLoading(false);
                console.log('error', error);
            });
    };

    const verifyByEmail = (event : any) => {
        event.preventDefault();
        setVerifiedByEmail(true);
        const emailVerificationCode_Url =
            process.env.REACT_APP_COMPANY_API_URL + `/Users/EmailVerificationCode/${userName}`;
        var requestOptions: any = {
            method: 'Post',
            redirect: 'follow'
        };
        fetch(emailVerificationCode_Url, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return 'error';
                }
            })
            .catch(error => {
                setVerifiedByEmailError(true);
                console.log('error', error);
            });
    }

    const [type, setType] = useState('password');

    return (
        <>
            {' '}
            {!passwordExpired ? (
                <>
                    <Helmet
                        bodyAttributes={{
                            class: 'login-page'
                        }}
                    ></Helmet>
                    {
                        <div className="login card">
                            {loading && <LoadingDisplay />}
                            <div className="login-details">
                                <img
                                    className="logo"
                                    src="https://reliantpayment.com/wp-content/themes/reliant/assets/img/main-logo.svg"
                                ></img>
                                <p>Welcome to Reliant Company Portal </p>
                                {mfaRequired && (
                                    <div className="login card qrcode">
                                        <h3>Enter verification code</h3>
                                        {verifiedByEmail ? <p>Enter verification code sent to your registered email</p> : 
                                        <p>Enter verification code generated by your Authenticator App</p>}
                                        <div className="row">
                                            <input
                                                type="text"
                                                placeholder={verifiedByEmail ? 'Email Verification Code' : 'Authenticator Verification Code'}
                                                onChange={e => setVerificationCode(e.target.value)}
                                            ></input>
                                        </div>
                                        <div className="login-button">
                                            <button onClick={() => validateFields()}>Submit</button>
                                        </div>
                                            {!verifiedByEmail ?
                                            <a className='verify-email' href='' onClick={(event) => verifyByEmail(event)}>Verify by Email Instead?</a> :
                                            <a className='verify-email' href='' onClick={(event) => {event.preventDefault(); setVerifiedByEmail(false)}}>Verify by Authenticator App?</a>}
                                    </div>
                                )}

                                {qrCode ? (
                                    <div className="login card qrcode">
                                        <h3>Scan this QR code with your Authenticator App</h3>
                                        <QRCodeSVG
                                            value={otpUri}
                                        />
                                        <div className="login-button">
                                            <button
                                                onClick={() => {
                                                    showQrCode(false);
                                                    setMfaRequired(true);
                                                }}
                                            >
                                                {' '}
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {!mfaRequired && (
                                            <div>
                                                <div className="row"></div>
                                                <div className="row">
                                                    <input
                                                        type="text"
                                                        placeholder="Username"
                                                        onChange={e => setUsername(e.target.value)}
                                                    ></input>
                                                </div>
                                                <div className="row">
                                                    <input
                                                        type={type}
                                                        placeholder="Password"
                                                        onChange={e => setPassword(e.target.value)}
                                                    ></input>
                                                    <span
                                                        className={`e-icons e-eye${
                                                            type == 'text' ? '' : '-slash'
                                                        }`}
                                                        onClick={() => {
                                                            if (type === 'text')
                                                                setType('password');
                                                            else setType('text');
                                                        }}
                                                    ></span>
                                                </div>
                                                {FORGOT_PASSWORD_URL && (
                                                    <a
                                                        className="login-forgot"
                                                        href={FORGOT_PASSWORD_URL}
                                                    >
                                                        Forgot Password
                                                    </a>
                                                )}
                                                <div className="login-button">
                                                    <button onClick={() => loadMfaProperties()}>
                                                        login
                                                    </button>
                                                </div>

                                                {(props.loginError || error) && (
                                                    <p className="login-error">
                                                        Invalid Credentials
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="login-block">
                                <img src={loginPage}></img>
                            </div>
                        </div>
                    }
                </>
            ) : (
                <ResetPassword />
            )}
        </>
    );
};
