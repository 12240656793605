import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Affiliate } from '../../../../types/affiliate';
import { UserRole } from '../../../../types/enums';
import { ReportType } from '../../../../types/report';
import CheckboxArray from '../../../common/form/CheckboxArray';
import WrappedInput from '../../../common/form/WrappedInput';
import NumericDropdown from '../../../common/NumericDropdown';

export enum CompanyPermission {
    ViewAffiliates = 0,
    LookupChecks = 1
}

export enum ConsumerPermission {
    EditSettlements = 0,
    EditConsumers = 1,
    ViewLedger = 2,
    EditFees= 3,
    EditDrafts= 4,
    ViewFees = 5,
    IssueRefunds = 6
}

export interface EditPermissions {
    userRole: UserRole | undefined;
    multiFactorAuthentication: number[];
    companyPermissions: CompanyPermission[];
    consumerPermissions: ConsumerPermission[];

    affiliateAccess: number[]; // list of affiliate ids
    reportTypeAccess: number[]; // list of report type ids
}

interface Props {
    affiliates: Affiliate[];
    reportTypes: ReportType[];
}

const EditUserFormInputs = (props: Props) => {
    const { control, watch } = useFormContext<EditPermissions>();
    const watchUserRole = watch('userRole');
    const isLimited = watchUserRole === UserRole.CompanyLimited;
    const [userRoleType, setUserRoleType] = React.useState<number | undefined>(watchUserRole);
    const userRoleTypeInput = (
        <WrappedInput<EditPermissions>
            inputName="userRole"
            labelText="User Type"
            helpText="Select the user type for this user. 
            Unlimited users have access to all Affiliates and screens 
            and can make and save changes to consumer information. 
            Limited users only have access to screens and Affiliates that you specify."
        >
            <Controller
                control={control}
                name="userRole"
                render={({ field: { onChange, value } }) => (
                    <NumericDropdown
                        options={[
                            { value: UserRole.CompanyUnlimited, text: 'Unlimited' },
                            { value: UserRole.CompanyLimited, text: 'Limited' }
                        ]}
                        selectedValue={value}
                        setSelectedValue={onChange}
                        setUserRoleTypeValue={(val) => {
                            setUserRoleType(val);
                        }}
                    />
                )}
            />
        </WrappedInput>
    );

    if (!isLimited || userRoleType != UserRole.CompanyLimited) {
        return <div>{userRoleTypeInput}</div>;
    }

    return (
        <div>
            {userRoleTypeInput}
            <WrappedInput<EditPermissions>
                inputName="multiFactorAuthentication"
                labelText="Multi Factor Authentication"
                helpText="Enable or disable multi-factor authentication (MFA) for this user. 
                MFA adds an extra layer of security by requiring users to provide a second form of verification in addition to their password."
            >
                <Controller
                    control={control}
                    name="multiFactorAuthentication"
                    render={({ field: { onChange, value } }) => (
                        <CheckboxArray
                            checkboxArrayId="chkArrCompanyPermission"
                            options={[
                                { id: 0, display: 'MFA Enabled' },
                            ]}
                            selectedOptions={value}
                            setSelectedOptions={onChange}
                        />
                    )}
                />
            </WrappedInput>
            <WrappedInput<EditPermissions>
                inputName="companyPermissions"
                labelText="Company Permissions"
                helpText="These options set permissions for 
                the selected user for items in the Company menu. 
                Unlimited users have access to all of the menu options. 
                For unlimited users, you can grant access to the 
                Affiliates, Fee Splits, and Check Lookup pages. 
                If a limited user does not have access to 
                one of these screens, that user cannot view that page."
            >
                <Controller
                    control={control}
                    name="companyPermissions"
                    render={({ field: { onChange, value } }) => (
                        <CheckboxArray
                            checkboxArrayId="chkArrCompanyPermission"
                            options={[
                                { id: CompanyPermission.LookupChecks, display: 'Lookup Checks' },
                                { id: CompanyPermission.ViewAffiliates, display: 'View Affiliates' }
                            ]}
                            selectedOptions={value}
                            setSelectedOptions={onChange}
                        />
                    )}
                />
            </WrappedInput>
            <WrappedInput<EditPermissions>
                inputName="consumerPermissions"
                labelText="Consumer Permissions"
                helpText="These options set permissions for 
                the selected user for items on the Consumer Page. 
                Unlimited users have access to all Consumer 
                information and can make and save changes. 
                If you grant the Add/Edit Settlements permission, 
                limited users can add and edit settlement entries for a consumer. 
                The View Customer Ledger option determines whether 
                or not the user can view each customer's ledger. 
                The ledger displays detailed fee split 
                information including amount and payee."
            >
                <Controller
                    control={control}
                    name="consumerPermissions"
                    render={({ field: { onChange, value } }) => (
                        <CheckboxArray
                            checkboxArrayId="chkArrConsumerPermission"
                            options={[
                                {
                                    id: ConsumerPermission.EditSettlements,
                                    display: 'Add/Edit Settlements'
                                },
                                { id: ConsumerPermission.EditConsumers, display: 'Edit Consumers' },
                                { id: ConsumerPermission.ViewLedger, display: 'View Ledger' },
                                { id: ConsumerPermission.EditFees, display: 'Add/Edit Fees' },
                                { id: ConsumerPermission.EditDrafts, display: 'Add/Edit Drafts' },
                                { id: ConsumerPermission.ViewFees, display: 'View Fees' },
                                { id: ConsumerPermission.IssueRefunds, display: 'Issue Refunds' }
                            ]}
                            selectedOptions={value}
                            setSelectedOptions={onChange}
                        />
                    )}
                />
            </WrappedInput>
            <WrappedInput<EditPermissions>
                inputName="affiliateAccess"
                labelText="Affiliate Permissions"
                helpText="This list displays all of the 
                Affiliates you currently have set up in the system. 
                On reports and on the Affiliates screen, 
                unlimited users can view all Affiliates. 
                However, limited users can only view the 
                Affiliates for which you provide them permission."
            >
                <Controller
                    name="affiliateAccess"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <CheckboxArray
                            checkboxArrayId="chkArrAffiliateAccess"
                            options={props.affiliates.map(a => ({ id: a.id, display: a.name }))}
                            selectedOptions={value ?? []}
                            setSelectedOptions={onChange}
                        />
                    )}
                />
            </WrappedInput>
            <WrappedInput<EditPermissions>
                inputName="reportTypeAccess"
                labelText="Report Permissions"
                helpText="This list displays all of the 
                reports currently available to you. 
                Unlimited users can view all reports. 
                Limited users can only view the reports you specify."
            >
                <Controller
                    control={control}
                    name="reportTypeAccess"
                    render={({ field: { onChange, value } }) => (
                        <CheckboxArray
                            checkboxArrayId="chkArrReportAccess"
                            options={props.reportTypes.map(rt => ({
                                id: rt.reportTypeId,
                                display: rt.reportName
                            }))}
                            selectedOptions={value ?? []}
                            setSelectedOptions={onChange}
                        />
                    )}
                />
            </WrappedInput>
        </div>
    );
};

export default EditUserFormInputs;
