import * as React from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../../api/baseApi';
import {
    getUserPermissions,
    resetUserPassword,
    updateUserLockout,
    updateUserPermissions
} from '../../../../api/userApi';
import { Affiliate } from '../../../../types/affiliate';
import { UserRole } from '../../../../types/enums';
import { ReportType } from '../../../../types/report';
import { User, UserPermissions, UserPermissionsUpdate } from '../../../../types/user';
import WrappedForm from '../../../common/form/WrappedForm';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import LoadingDisplay from '../../../common/LoadingDisplay';
import MessagePopup from '../../../common/MessagePopup';
import EditAffiliateUserFormInputs from '../affiliates/EditAffiliateUserFormInputs';
import EditUserFormInputs, {
    CompanyPermission,
    ConsumerPermission,
    EditPermissions
} from './EditUserFormInputs';

interface Props {
    user: User;
    updateUser: (user: User) => void;
    affiliates: Affiliate[];
    reportTypes: ReportType[];
    isAffiliateUser?: boolean;
}

const convertPermissionsToExternal = (
    internalPermissions: EditPermissions,
    affiliatePermissionsFromServer?: UserPermissions
) => {
    const isLimited = internalPermissions.userRole === UserRole.CompanyLimited;
    let externalPermissions: UserPermissionsUpdate;

    // For Affiliate users, most form fields are NOT used on the form.
    // React hook forms will not submit the initialized values for fields that are not used on a form, but rather "undefined".
    // Therefore, overwrite any such unused fields with their original values from the server, so they remain unchanged on update.
    if (affiliatePermissionsFromServer) {
        externalPermissions = {
            mfaEnabled: affiliatePermissionsFromServer.mfaEnabled,
            viewAffiliates: affiliatePermissionsFromServer.viewAffiliates,
            lookupChecks: affiliatePermissionsFromServer.lookupChecks,
            editSettlements: affiliatePermissionsFromServer.editSettlements,
            editConsumers: affiliatePermissionsFromServer.editConsumers,
            viewLedger: affiliatePermissionsFromServer.viewLedger,
            editFees: affiliatePermissionsFromServer.editFees,
            editDrafts: affiliatePermissionsFromServer.editDrafts,
            viewFees: affiliatePermissionsFromServer.viewFees,
            issueRefunds: affiliatePermissionsFromServer.issueRefunds,
            affiliateAccess: affiliatePermissionsFromServer.affiliateAccess ?? [],
            reportTypeAccess: internalPermissions.reportTypeAccess ?? [], // only field present on form
            isLimited: true
        };
    } else {
        externalPermissions = {
            mfaEnabled: internalPermissions.multiFactorAuthentication.includes(0),
            viewAffiliates:
                !isLimited ||
                internalPermissions.companyPermissions.includes(CompanyPermission.ViewAffiliates),
            lookupChecks:
                !isLimited ||
                internalPermissions.companyPermissions.includes(CompanyPermission.LookupChecks),
            editSettlements:
                !isLimited ||
                internalPermissions.consumerPermissions.includes(
                    ConsumerPermission.EditSettlements
                ),
            editConsumers:
                !isLimited ||
                internalPermissions.consumerPermissions.includes(ConsumerPermission.EditConsumers),
            viewLedger:
                !isLimited ||
                internalPermissions.consumerPermissions.includes(ConsumerPermission.ViewLedger),
            editFees: 
                !isLimited ||
                internalPermissions.consumerPermissions.includes(ConsumerPermission.EditFees),
            editDrafts: 
                !isLimited ||
                internalPermissions.consumerPermissions.includes(ConsumerPermission.EditDrafts),
            viewFees:
                !isLimited ||
                internalPermissions.consumerPermissions.includes(ConsumerPermission.ViewFees),
            issueRefunds:
                !isLimited ||
                internalPermissions.consumerPermissions.includes(ConsumerPermission.IssueRefunds),
            affiliateAccess: internalPermissions.affiliateAccess ?? [],
            reportTypeAccess: internalPermissions.reportTypeAccess ?? [],
            isLimited: isLimited
        };
    }
    return externalPermissions;
};

const convertPermissionsToInternal = (externalPermissions: UserPermissions, userRole: UserRole|undefined) => {
    const internalPermissions: EditPermissions =  {
        ...externalPermissions,
        userRole: userRole && userRole,
        companyPermissions: [],
        consumerPermissions: [],
        multiFactorAuthentication: externalPermissions.mfaEnabled ? [0] : []
    };

    if (externalPermissions.viewAffiliates) {
        internalPermissions.companyPermissions.push(CompanyPermission.ViewAffiliates);
    }

    if (externalPermissions.lookupChecks) {
        internalPermissions.companyPermissions.push(CompanyPermission.LookupChecks);
    }

    if (externalPermissions.editSettlements) {
        internalPermissions.consumerPermissions.push(ConsumerPermission.EditSettlements);
    }

    if (externalPermissions.editConsumers) {
        internalPermissions.consumerPermissions.push(ConsumerPermission.EditConsumers);
    }

    if (externalPermissions.viewLedger) {
        internalPermissions.consumerPermissions.push(ConsumerPermission.ViewLedger);
    }

    if (externalPermissions.editFees) {
        internalPermissions.consumerPermissions.push(ConsumerPermission.EditFees);
    }

    if (externalPermissions.editDrafts) {
        internalPermissions.consumerPermissions.push(ConsumerPermission.EditDrafts);
    }

    if (externalPermissions.viewFees) {
        internalPermissions.consumerPermissions.push(ConsumerPermission.ViewFees);
    }

    if (externalPermissions.issueRefunds) {
        internalPermissions.consumerPermissions.push(ConsumerPermission.IssueRefunds);
    }

    return internalPermissions;
};

const EditUserDisplay = (props: Props) => {
    const [permissions, setPermissions] = useState<UserPermissions>();
    const [loading, setLoading] = useState(true);
    const [userRoles, setUserRoles] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    const apiWrapper = useMemo(() => loadFromApi(setApiError, setLoading), [setApiError]);
    const toastSuccess = (message: string) =>
        addToast(message, { appearance: 'success', autoDismiss: true });

    useEffect(() => {
        apiWrapper(() => getUserPermissions(props.user.userId), setPermissions);
    }, [props.user, apiWrapper]);

    const unlockUser = () =>
        apiWrapper(
            () => updateUserLockout(props.user.userId, false),
            () => props.updateUser({ ...props.user, isLockedOut: false }),
            () => toastSuccess('Unlocked User')
        );

    const resetPassword = () =>
        apiWrapper(
            () => resetUserPassword(props.user.userId),
            undefined,
            () => toastSuccess('Password Reset Triggered')
        );

    const submitUserUpdate = (internalPermissions: EditPermissions) =>{
        apiWrapper(
            () =>
                updateUserPermissions(
                    props.user.userId,
                    convertPermissionsToExternal(
                        internalPermissions,
                        props.isAffiliateUser ? permissions : undefined
                    )
                ),
            undefined,
            async () => { 
                setUserRoles(true)
                await apiWrapper(() => getUserPermissions(props.user.userId), setPermissions,
                () =>{
                    setTimeout(() => {
                        setUserRoles(false)
                    }, 500)
                    toastSuccess('Updated Permissions')
                });               
            }
        );
    }

    return loading || userRoles ? (
        <LoadingDisplay />
    ) : permissions ? (
        <div id="userDisplay">
            <div className="user-field">
                <label>Username</label>
                <span>{props.user.username}</span>
            </div>
            <div className="user-field">
                <label>Email</label>
                <span>{props.user.email}</span>
            </div>
            <div className="user-field">
                <label>Actions</label>
                {props.user.isLockedOut && (
                    <button className="btn btn-secondary" onClick={unlockUser}>
                        Unlock
                    </button>
                )}
                <button className="btn btn-secondary" onClick={() => setShowConfirm(true)}>
                    Reset Password
                </button>
                <MessagePopup
                    targetId="userDisplay"
                    message="Are you sure you want to reset this user's password?"
                    showDialog={showConfirm}
                    setShowDialog={setShowConfirm}
                    requireConfirmation={true}
                    onConfirmClick={resetPassword}
                />
            </div>

            <WrappedForm<EditPermissions>
                initialValues={convertPermissionsToInternal(permissions,permissions?.userRole)}
                onSubmit={submitUserUpdate}
            >
                {props.isAffiliateUser ? (
                    <EditAffiliateUserFormInputs
                        affiliates={props.affiliates}
                        reportTypes={props.reportTypes}
                    />
                ) : (
                    <EditUserFormInputs
                        affiliates={props.affiliates}
                        reportTypes={props.reportTypes}
                    />
                )}
                <div className="clear-fix">
                    <input
                        className="btn btn-primary float-left"
                        type="submit"
                        value="Update Permissions"
                    />
                </div>
            </WrappedForm>
        </div>
    ) : (
        <LoadFailedDisplay />
    );
};

export default EditUserDisplay;
